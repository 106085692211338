import React from 'react';
import './App.scss';
import Tae from './Tae.js';
import {loanTypes} from './constants.js';

export default class App extends React.Component {
    constructor(props) {
        super(props);
        // Important KeyTae to generate a NEW element <Tae/>
        this.keyTae = 0;
        this.state = {
            name: 'loan1',
            grace: false,
            showActualLoan: false,
            actualLoan: {
                name: "Loan1",
                grace: false
            },
            loans: {}
        };

        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnSave = this.handleOnSave.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSelectSavedLoan = this.handleSelectSavedLoan.bind(this);
    }

    handleOnChange(e) {
        if (e.target.name === 'name') {
            this.setState({
                name: e.target.value
            });
        } else if (e.target.name === 'grace') {
            this.setState({
                grace: !this.state.grace
            });
        }
    }

    handleOnClick(e) {
        const type = e.target.name;
        const actualLoan = {
            name: this.state.name,
            grace: this.state.grace,
            type: type
        };
        this.keyTae++;
        this.setState({
            showActualLoan: true,
            actualLoan: actualLoan
        });
    }

    handleOnSave(loan) {
        const loans = this.state.loans;
        let name = this.state.name;
        //random colour
        if (!loan.colour) {
            const r = Math.round(Math.random() * 220) + 35;
            const g = Math.round(Math.random() * 220) + 35;
            const b = Math.round(Math.random() * 220) + 35;
            loan['colour'] = `rgba(${r},${g},${b},0.5)`;

            // update input name by default adding ordinal
            do {
                const lastChar = Number(name.substr(name.length -1));
                if (isNaN(lastChar)) {
                    name = name + '1';
                } else {
                    let appendedNumber = name.match(/\d+$/)[0];
                    name = name.substring(0, name.length - appendedNumber.length).concat((++appendedNumber).toString());
                }
            } while (name in loans)
        }

        loan['showMultiButton'] = false;
        loans[loan.name] = loan;
        this.setState({
            name: name,
            loans : loans,
            actualLoan: loan
        });
    }

    handleClose(e) {
        const name = e.target.getAttribute('id');
        const loans = this.state.loans;
        delete loans[name];
        this.setState({
            loans : loans,
            showActualLoan: this.state.actualLoan.name !== name,
        });
    }

    handleSelectSavedLoan(e) {
        const name = e.target.getAttribute('id');
        const loans = this.state.loans;
        this.keyTae++;
        this.setState({
            showActualLoan: true,
            actualLoan : loans[name]
        });
    }

    render() {
        const actualLoan = this.state.showActualLoan ? <Tae key={this.keyTae} loan={this.state.actualLoan} onSave={this.handleOnSave}/> : null;
        const savedLoans = this.state.loans.length === 0 ? null : Object.keys(this.state.loans).map((key) => {return(
            <div className="savedLoan col" key={key} style={{backgroundColor: this.state.loans[key].colour}}>
                <div id={key} onClick={this.handleSelectSavedLoan}>
                    {key} {loanTypes[this.state.loans[key].type]}<br/>TAE: {this.state.loans[key].loanResults.tae}%
                </div><div className="closeX" id={key} onClick={this.handleClose}>X</div>
            </div>
        )});
        return (
            <div className="App">
                <h1>Loan Comparator</h1>
                <blockquote>"Take always the lowest APR loan"<br/><small><cite>Nostradamus</cite></small></blockquote>
                <LoanCreate name={this.state.name} grace={this.state.grace} onChange={this.handleOnChange} onClick={this.handleOnClick}/>
                <div className="container"><div className="row">{savedLoans}</div></div>
                {actualLoan}
            </div>
        );
    }

}

function LoanCreate(props) {
    const loans = Object.keys(loanTypes).map((key, keyNum) => {
        return (
            <button className="btn btn-primary ml-2 mt-4" key={keyNum} name={key} onClick={props.onClick}>{loanTypes[key]}</button>
        );
    });
    return (
        <div className="inputs">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">Name</span>
                </div>
                <input className="form-control" type="text" name="name" value={props.name} onChange={props.onChange}/>
                <div className="input-group-append">
                    <div className="input-group-text">
                        <input type="checkbox" name="grace" defaultChecked={props.grace} onChange={props.onChange}/>
                        <span className="ml-2">Grace term</span>
                    </div>
                </div>
            </div>
            {loans}
        </div>
    );
}
