const taeInputs  = {
    amount: {
        label: "Amount",
        label2: "€",
        help: "The amount of money the financial institution has given us and we must return"
    },
    termYears: {
        label: "Term",
        label2: "Years",
        help: "The duration in years of the loan. The time that we will be paying fees to repay the debt."
    },
    feesPerYear: {
        label: "Number of Fees",
        label2: "Per Year",
        help: "The number of fees paid in a year. Usually, 12 payments, one per month."
    },
    fixedRateInterest: {
        label: "Fixed-rate interest",
        label2: "%",
        help: "The fixed annual interest rate during the fixed rate."
    },
    fixedRateTerm: {
        label: "Fixed-rate Period",
        label2: "Fees",
        help: "The number of payments to fixed interest period. (If you make one payment per month coincides with the number of months in the fixed rate period)"
    },
    referenceInterest: {
        label: "Reference Interest",
        label2: "%",
        help: "The value of the Reference Interest Elected generally EURIBOR one year. In the Options you can find the latest rates and adjust them as defaults."
    },
    differential: {
        label: "Differential interest",
        label2: "%",
        help: "Interest you has agreed with your entity to add the Reference Interest. Example: If you have agreed a EURIBOR +0.85, 0.85 should be placed in this box."
    },
    graceTerm: {
        label: "Grace Period",
        label2: "Fees",
        help: "The number of initial payments during which only interest will be paid, remaining the amount of debt the same. It is generally used by promoters during the construction of the building."
    },
    openingCommissionPerCent: {
        label: "Opening Commission",
        label2: "%",
        help: "The percentage of the total loan amount that the financial institution will be charged for processing and study your loan."
    },
    otherOpeningFees: {
        label: "Other Opening Fees",
        label2: "€",
        help: "The additional cost the Bank has been forced you to contract to get the loan, which otherwise you would not have contract. Such as opening accounts, credit cards, life insurance and home insurance, payment of rent insurance, etc.. Do not include here the Tax and Notary costs, Agency and Taxation unless the entity requires to complete the formalities with a particular Notary, Assessor and/or Agency, in which case you should add here the additional costs over the normal market price."
    }
};
export default taeInputs;

export const loanTypes  = {
    FIX: 'Fixed Rate',
    VARIABLE: 'Variable Rate',
    FIX_VARIABLE: 'Fixed & Variable Rate',
};
