export default class Loan {
    amount;
    termYears;
    feesPerYear;
    fixedRateInterest;
    fixedRateTerm;
    referenceInterest;
    differential;
    openingCommissionPerCent;
    otherOpeningFees;
    graceTerm;

    constructor(amount, termYears, feesPerYear, fixedRateInterest, fixedRateTerm,
                referenceInterest, differential, openingCommissionPerCent, otherOpeningFees, graceTerm) {
        this.amount = amount;
        this.termYears = termYears;
        this.feesPerYear = feesPerYear;
        this.fixedRateInterest = fixedRateInterest;
        this.fixedRateTerm = fixedRateTerm;
        this.referenceInterest = referenceInterest;
        this.differential = differential;
        this.openingCommissionPerCent = openingCommissionPerCent;
        this.otherOpeningFees = otherOpeningFees;
        this.graceTerm = graceTerm;
    }

    /**
     * Get the fee for fixed-Rate loan with total amount
     * @param interest nominal annual percent
     * @param nper number of fees
     */
    fixedRateFee(interest, nper) {
        const i = interest / this.feesPerYear / 100;
        if (nper === 0) return 0;
        else if (i === 0) return this.amount / (this.feesPerYear * this.termYears);
        else return Math.floor(100 * this.amount * (i * Math.pow(1 + i, nper) / (Math.pow(1 + i, nper) - 1))) / 100;
    }

    /**
     * Get the fee for fixed-Rate loan with given amount
     * @param interest nominal annual percent
     * @param nper number of fees
     * @param amount given amount
     */
    fixedRateFeeWithAmount(interest, nper, amount) {
        const i = interest / this.feesPerYear / 100;
        if (nper === 0) return 0;
        else if (i === 0) return amount / nper;
        else return Math.floor(100 * amount * (i * Math.pow(1 + i, nper) / (Math.pow(1 + i, nper) - 1))) / 100;
    }

    gracePeriodFee(interest) {
        const i = interest / this.feesPerYear / 100;
        return Math.floor(100 * this.amount * i) / 100;
    }

    variableFee(interest) {
        const nper = this.termYears * this.feesPerYear;
        const nperInitial = this.fixedRateTerm - this.graceTerm;
        let loan = this.amount;
        // Calculate the amortized until the beginning of the variable period.
        if (nperInitial > 0) {
            const fee = this.fixedRateFee(this.fixedRateInterest, nper - this.graceTerm);
            for (let c = 1; c <= nperInitial; c++) {
                loan = loan * (1 + this.fixedRateInterest / this.feesPerYear / 100) - fee;
            }
            return this.fixedRateFeeWithAmount(interest, nper - Math.max(this.fixedRateTerm, this.graceTerm), loan);
        } else {
            return this.fixedRateFee(interest, nper - this.graceTerm);
        }
    }

    static newtonItFixedRate(z, d, fee, nper) {
        const p = -d + z * (fee + d) - fee * Math.pow(z, nper + 1);
        const pDev = fee + d - fee * nper * Math.pow(z, nper);
        return p / pDev;
    }

    newtonItVariableRate(z, d, graceFee, fixedRateFee, variableRateFee, nperGrace, nperFixedRate, nperVariableRate) {
        let p = -d;
        let pDev = 0;
        if (nperGrace <= nperFixedRate) {
            for (let c = 1; c <= nperGrace; c++) {
                p = p + graceFee * Math.pow(z, c);
                pDev = pDev + c * graceFee * Math.pow(z, c - 1);
            }
            for (let c = nperGrace + 1; c <= nperFixedRate; c++) {
                p = p + fixedRateFee * Math.pow(z, c);
                pDev = pDev + c * fixedRateFee * Math.pow(z, c - 1);
            }
            for (let c = nperFixedRate + 1; c <= nperFixedRate + nperVariableRate; c++) {
                p = p + variableRateFee * Math.pow(z, c);
                pDev = pDev + c * variableRateFee * Math.pow(z, c - 1);
            }
            return p / pDev;
        } else {
            for (let c = 1; c <= nperFixedRate; c++) {
                p = p + graceFee * Math.pow(z, c);
                pDev = pDev + c * graceFee * Math.pow(z, c - 1);
            }
            // when grace period enters on variable term
            graceFee = this.gracePeriodFee(this.referenceInterest + this.differential);
            for (let c = nperFixedRate + 1; c <= nperGrace; c++) {
                p = p + graceFee * Math.pow(z, c);
                pDev = pDev + c * graceFee * Math.pow(z, c - 1);
            }
            for (let c = nperGrace + 1; c <= nperFixedRate + nperVariableRate; c++) {
                p = p + variableRateFee * Math.pow(z, c);
                pDev = pDev + c * variableRateFee * Math.pow(z, c - 1);
            }
            return p / pDev;
        }
    }

    //  ------------------------  GETTERS ----------------------------------

    getFixedRateTAE() {
        const i = this.fixedRateInterest / this.feesPerYear / 100;
        const commissions = this.otherOpeningFees + this.openingCommissionPerCent * this.amount / 100;
        const nper = this.termYears * this.feesPerYear;
        let counter = 50; // MAX NUM OF ITERATIONS
        let z = Math.pow(1 + i, -1);
        const fee = this.fixedRateFee(this.fixedRateInterest, nper - this.graceTerm);
        let d = this.amount - commissions;
        let zOld;
        do {
            counter--;
            zOld = z;
            z = z - Loan.newtonItFixedRate(z, d, fee, nper);
        } while (z !== zOld && counter > 0);
        if (isNaN(z)) return 0;
        else return 100 * (Math.pow(1 / z, this.feesPerYear) - 1);
    }

    getVariableRateTAE() {
        const i = this.fixedRateInterest / this.feesPerYear / 100;
        const commissions = this.otherOpeningFees + this.openingCommissionPerCent * this.amount / 100;
        const nper = this.termYears * this.feesPerYear;
        let counter = 50; // MAX NUM OF ITERATIONS
        const variableTerm = nper - this.fixedRateTerm
        let z = Math.pow(1 + i, -1);
        const d = this.amount - commissions;
        const graceFee = this.gracePeriodFee(this.fixedRateInterest);
        const fixedRateFee = this.fixedRateFee(this.fixedRateInterest, nper - this.graceTerm);
        const variableRateFee = this.variableFee(this.referenceInterest + this.differential);
        let zOld;
        do {
            counter--;
            zOld = z;
            z = z - this.newtonItVariableRate(z, d, graceFee, fixedRateFee, variableRateFee,
                this.graceTerm, this.fixedRateTerm, variableTerm);
        } while (z !== zOld && counter > 0);
        if (isNaN(z)) return 0;
        else return 100 * (Math.pow(1 / z, this.feesPerYear) - 1);
    }

    getGraceFee() {
        return this.graceTerm > 0 ? this.gracePeriodFee(this.fixedRateInterest) : 0;
    }

    getGraceFeeIntoVariableTerm() {
        return this.graceTerm > this.fixedRateTerm ? this.gracePeriodFee(this.referenceInterest + this.differential) : 0;
    }

    getFixedRateFee() {
        return this.graceTerm < this.fixedRateTerm ? this.fixedRateFee(this.fixedRateInterest, this.termYears * this.feesPerYear - this.graceTerm) : 0;
    }

    getVariableRateFee() {
        return this.variableFee(this.referenceInterest + this.differential);
    }

    getGraceFeesCount() {
        return this.graceTerm < this.fixedRateTerm ? this.graceTerm : this.fixedRateTerm;
    }

    getGraceFeesIntoVariableTermCount() {
        return this.graceTerm < this.fixedRateTerm ? 0 : this.graceTerm - this.fixedRateTerm;
    }

    getFixedRateFeesCount() {
        return this.fixedRateTerm > this.graceTerm ? this.fixedRateTerm - this.graceTerm : 0;
    }

    getVariableRateFeesCount() {
        return this.feesPerYear * this.termYears - this.getGraceFeesCount() - this.getGraceFeesIntoVariableTermCount() - this.getFixedRateFeesCount();
    }

    getOpeningFees() {
        return Math.round(this.otherOpeningFees * 100 + this.openingCommissionPerCent * this.amount) / 100
    }
}