import React from 'react';
import taeInputs from './constants.js';
import Loan from './Loan.js';
import {loanTypes} from './constants.js';

export default class Tae extends React.Component {
    constructor(props) {
        super(props);

        this.loanResults = {};
        this.inputs = Object.assign({}, taeInputs);

        if (props.loan.type === 'VARIABLE') {
            delete this.inputs['fixedRateInterest'];
            delete this.inputs['fixedRateTerm'];
        }

        if (props.loan.type === 'FIX') {
            delete this.inputs['fixedRateTerm'];
            delete this.inputs['referenceInterest'];
            delete this.inputs['differential'];
        }

        if (!props.loan.grace) {
            delete this.inputs['graceTerm'];
        }

        if (props.loan.hasOwnProperty('amount')) {
            this.state = props.loan;
        } else {
            this.state = {
                name: props.loan.name,
                grace: props.loan.grace,
                type: props.loan.type,
                colour: props.loan.colour,
                amount: 60000,
                termYears: 10,
                feesPerYear: 12,
                fixedRateInterest: props.loan.type === 'VARIABLE' ? 3 : 5,
                fixedRateTerm: props.loan.type === 'FIX' ? 120 : props.loan.type === 'VARIABLE' ? 0 : 6,
                referenceInterest: props.loan.type === 'FIX' ? 0 : 2,
                differential: props.loan.type === 'FIX' ? 0 : 1,
                openingCommissionPerCent: 1,
                otherOpeningFees: 0,
                graceTerm: props.loan.grace ? 3 : 0,
                loanResults: this.loanResults,
                isCalculated: false,
                showMultiButton: true,
            };
        }

        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleMultiButton = this.handleMultiButton.bind(this);
    }

    handleMultiButton() {
        if (this.state.isCalculated) {
            this.props.onSave(this.state);
            this.setState({
                showMultiButton: false
            });
        } else {
            this.setState({
                isCalculated: true
            });
            this.calculate();
        }
    }

    handleChangeInput(event) {
        let value = event.target.value.replace(',','.');
        const name = event.target.name;

        if (isNaN(value)) {
            return;
        }

        if (['termYears', 'feesPerYear', 'fixedRateTerm', 'graceTerm'].includes(name)) {
            value = parseInt(value);
        }

        if (this.props.loan.type === 'FIX' && ['termYears', 'feesPerYear'].includes(name)) {
            this.setState({
                fixedRateTerm: name === 'termYears' ? this.state.feesPerYear * value : this.state.termYears * value
            });
        }

        this.setState({
            [name]: value,
            showMultiButton: true
        }, this.state.isCalculated ? this.calculate : null);
    }

    calculate() {
        const loan = new Loan(
            Number(this.state.amount),
            Number(this.state.termYears),
            Number(this.state.feesPerYear),
            Number(this.state.fixedRateInterest),
            Number(this.state.fixedRateTerm),
            Number(this.state.referenceInterest),
            Number(this.state.differential),
            Number(this.state.openingCommissionPerCent),
            Number(this.state.otherOpeningFees),
            Number(this.state.graceTerm)
        );

        if (this.props.loan.type === 'FIX') {
            this.loanResults['tae'] = loan.getFixedRateTAE();
            this.loanResults['variableRateFee'] = 0;
        } else {
            this.loanResults['tae'] = loan.getVariableRateTAE();
            this.loanResults['variableRateFee'] = loan.getVariableRateFee();
        }

        this.loanResults['tae'] = this.loanResults.tae < 0.001 ? '' : this.loanResults.tae.toFixed(3);
        this.loanResults['graceFee'] = loan.getGraceFee();
        this.loanResults['graceFeeIntoVariableTerm'] = loan.getGraceFeeIntoVariableTerm();
        this.loanResults['fixedRateFee'] = loan.getFixedRateFee();
        this.loanResults['graceFeesCount'] = loan.getGraceFeesCount();
        this.loanResults['graceFeesIntoVariableTermCount'] = loan.getGraceFeesIntoVariableTermCount();
        this.loanResults['fixedRateFeesCount'] = loan.getFixedRateFeesCount();
        this.loanResults['variableRateFeesCount'] = loan.getVariableRateFeesCount();
        this.loanResults['openingFees'] = loan.getOpeningFees();

        this.setState({
            loanResults: this.loanResults
        })
    }

    render() {
        const inputs = Object.keys(this.inputs).map((key, keyNum) => {
            return(
                <Input key={keyNum} label={this.inputs[key].label} label2={this.inputs[key].label2} help={this.inputs[key].help} name={key} showHelp={true} value={this.state[key]} onChange={this.handleChangeInput}/>
            );
        });
        const loanResults = this.state.loanResults;
        const fees = [];
        let amortization;
        if (Object.keys(loanResults).length > 0) {
            if (loanResults.tae !== '') {
                fees.push(<p key={0}><b>APR: {loanResults.tae}%</b></p>);
            }
            if (loanResults.graceFeesCount > 0) {
                fees.push(<p key={1}>{loanResults.graceFeesCount} Fees on Grace term of {loanResults.graceFee.toFixed(2)}€</p>);
            }
            if (loanResults.graceFeesIntoVariableTermCount > 0) {
                fees.push(<p key={2}>{loanResults.graceFeesIntoVariableTermCount} Fees on Grace term of {loanResults.graceFeeIntoVariableTerm.toFixed(2)}€</p>);
            }
            if (loanResults.fixedRateFeesCount > 0) {
                fees.push(<p key={3}>{loanResults.fixedRateFeesCount} Fees at Fixed-rate of {loanResults.fixedRateFee.toFixed(2)}€</p>);
            }
            if (loanResults.variableRateFeesCount > 0) {
                fees.push(<p key={4}>{loanResults.variableRateFeesCount} Fees at Variable-rate of {loanResults.variableRateFee.toFixed(2)}€</p>);
            }
            fees.push(<p key={5}>Opening commissions and other opening
                fees: {loanResults.openingFees.toFixed(2)}€</p>);
            fees.push(<p key={6}>Total sum of loan fees: {(Math.round((loanResults.graceFeesCount * loanResults.graceFee
                + loanResults.graceFeesIntoVariableTermCount * loanResults.graceFeeIntoVariableTerm
                + loanResults.fixedRateFeesCount * loanResults.fixedRateFee
                + loanResults.variableRateFeesCount * loanResults.variableRateFee
                + loanResults.openingFees
            ) * 100) / 100).toFixed(2)
            }€</p>);
            amortization = <Amortization type={this.props.loan.type} loan={this.state}/>;
        }
        const multiButton = this.state.showMultiButton ? <button className="btn btn-primary" onClick={this.handleMultiButton}>{this.state.isCalculated ? 'SAVE' : 'CALCULATE'}</button> : null;
        const withGrace = this.state.grace ? 'with Grace' : null;

        return (
            <div className="Tae" style={{backgroundColor: this.props.loan.colour}}>
                <h2>{this.props.loan.name}</h2>
                <h3>{loanTypes[this.props.loan.type]} {withGrace}</h3>
                <div className="inputs">{inputs}</div>
                {multiButton}
                {fees}
                <div className="tableFixHead">{amortization}</div>
            </div>
        );
    }
}

function Amortization(props) {
    const headContent = [
        'Fee #',
        'Date',
        'Interest rate',
        'Int./Fee',
        'Fee',
        'Interest',
        'Amortization',
        'Pending loan'
    ];
    const loan = {
        amount: Number(props.loan.amount),
        termYears: Number(props.loan.termYears),
        feesPerYear: Number(props.loan.feesPerYear),
        fixedRateInterest: Number(props.loan.fixedRateInterest),
        fixedRateTerm: Number(props.loan.fixedRateTerm),
        referenceInterest: Number(props.loan.referenceInterest),
        differential: Number(props.loan.differential),
        graceTerm: Number(props.loan.graceTerm),
        loanResults: props.loan.loanResults
    };
    const head = headContent.map((value, key) => <th key={key}>{value}</th>);
    const body = [];
    const date = new Date();
    const feesNumber = loan.termYears * loan.feesPerYear;
    let month = Math.round((date.getMonth() + 1) * loan.feesPerYear / 12);
    let year = date.getFullYear().toString().substring(2);
    let amount = loan.amount;
    let interestPerYear;
    let interestPerFee;
    let interest;
    let amortization;
    let fee;

    for (let i = 0; i < feesNumber; i++) {
        month++;
        if (month > loan.feesPerYear) {
            month = 1;
            year++;
        }
        if (i < loan.graceTerm && i < loan.fixedRateTerm) {
            fee = loan.loanResults.graceFee;
        } else if (i < loan.graceTerm) {
            fee = loan.loanResults.graceFeeIntoVariableTerm;
        } else if (i < loan.fixedRateTerm) {
            fee = loan.loanResults.fixedRateFee;
        } else {
            fee = loan.loanResults.variableRateFee;
        }

        if (i < loan.fixedRateTerm) {
            interestPerYear = loan.fixedRateInterest;
        } else {
            interestPerYear = loan.referenceInterest + loan.differential;
        }

        interestPerFee = interestPerYear / loan.feesPerYear;
        interest = Math.round(amount * interestPerFee) / 100;
        amortization =  Math.round((fee - interest) * 100) / 100;
        amount = Math.round((amount - amortization) * 100) / 100;
        const rowContent = [
            i + 1,
            month + '/' + year,
            interestPerYear.toFixed(3),
            (Math.round(interestPerFee * 1000) / 1000).toFixed(3),
            fee.toFixed(2),
            interest.toFixed(2),
            amortization.toFixed(2),
            amount.toFixed(2)
        ];
        body.push(<tr key={i}>{rowContent.map((value, key) => <td key={key}>{value}</td>)}</tr>);
    }

    return(<table className="table table-hover">
        <thead className="thead-light"><tr>{head}</tr></thead>
        <tbody>{body}</tbody>
    </table>);
}

class Input extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showHelp: false
        };
        this.handleInfo = this.handleInfo.bind(this);
    }

    handleInfo() {
        this.setState({
            showHelp: !this.state.showHelp
        });
    }

    render() {
        let help = '';

        if (this.state.showHelp) {
            help = this.props.help;
        }

        return (
            <div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text">{this.props.label}</span>
                    </div>
                    <input className="form-control" type="text" name={this.props.name} value={this.props.value} onChange={(e) => this.props.onChange(e)}/>
                    <div className="input-group-append">
                        <span className="input-group-text">{this.props.label2}</span>
                        <button className="btn btn-outline-secondary" name={this.props.name} onClick={this.handleInfo}>+info</button>
                    </div>
                </div>
                <p className="help">{help}</p>
            </div>
        );
    }

}